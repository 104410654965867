import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user

  constructor(
    private _http: HttpClient
  ) { }

  public get getUser() {
    return this.user;
  }
  public set setUser(user) {
    this.user = user;
  }

  public getForm(CustomerID, identified) {
    
    return this._http.post(environment.backendUrl+'/customer/?form=true',{'customer':CustomerID,'identified':identified})
    .pipe(
      map((res: any) => {
        if (res['status'] == 200 && res['data'].length > 0 ) {
          this.user = res['data'][0]
          return res;
          
        }
        return false 
      })

    );
  }

}
