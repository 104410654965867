import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared-components/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form_login: FormGroup;

  constructor(
    private _user: UserService,
    private _router: Router,
    private _alert: AlertService,
    private fb: FormBuilder,
  ) {
    this.form_login = fb.group({
      code: [null, Validators.required],
      identified: [null, Validators.required]
    })
  }

  
  numberOnly(event: any) {
    const pattern = /[0-9\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
    }
  }
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
    }
  }

  ngOnInit() {
  }

  public login() {
    if (this.form_login.value.code) {
      if (this.form_login.value.identified) {
        this._user.getForm(this.form_login.value.code, this.form_login.value.identified).subscribe(
          (res) => {
              if (res !== false) {
                this._alert.setOpen({ open: true, message: 'Ingreso exitoso', level: 'success' })
                this._router.navigate(['/forms'])
              } else {
                this._alert.setOpen({ open: true, message: 'Código o Identificación Errado', level: 'danger' })
              }
          },
          (err) => {
            console.log(err.error);
          }
        )
      }else{
        this._alert.setOpen({open: true, message: 'Por favor ingrese la identificación', level: 'warning'})
      }
    } else {
      this._alert.setOpen({ open: true, message: 'Por favor ingrese el código', level: 'warning' })
    }
  }

  /*private isUndefinedOrEmpty(value: any): boolean {
    return value === undefined || (value + '').trim() === '';
  }*/
}
