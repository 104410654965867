import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'spiner',
  template: `<div class="lds-ellipsis"><div [ngStyle]="{background: spinColor}"></div><div [ngStyle]="{background: spinColor}"></div><div [ngStyle]="{background: spinColor}"></div><div [ngStyle]="{background: spinColor}"></div></div>`,
  styleUrls: ['./spiner.component.scss']
})
export class SpinerComponent implements OnInit {

  @Input('spinColor') spinColor = 'red'

  constructor() { }

  ngOnInit() {
  }

}
