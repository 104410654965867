import { Component } from '@angular/core';
import { slider, fader } from './animaciones';
import { RouterOutlet } from '@angular/router';
import { AlertService } from './shared-components/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider,
    // fader
  ]
})
export class AppComponent {

  public alert = {
    open: false,
    message: '',
    level: ''
  }

  constructor(
    private _alert: AlertService
  ) { }

  ngOnInit(): void {
    this._alert.getOpen().subscribe((value) => this.alert = value)
    // setInterval(() => {
    //   this._alert.networkOn().subscribe(
    //     (res) => {

    //     },
    //     (err) => {
    //       if (err.status != 200) {
    //         this._alert.setOpen({
    //           open: true,
    //           message: 'Contecte el dispositivo a internet',
    //           level: 'warning'
    //         })
    //       }
    //     },
    //   );
    // }, 4500)
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public toggleAlert(e) {
    if (e.ok) {
      this.alert.open ? this.alert.open = false : this.alert.open = true;
    }
  }

}
